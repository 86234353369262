.Modal {
  position: fixed;
  width: 90%;
  top: 50%;
  left: 5%;
  transform: translate(0%, -50%);
  height: fit-content;
  max-height: 90vh;
  background-color: var(--color-secondary-01);
  border-radius: 5px;
  z-index: 100;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow: auto;
}

.contrastModal {
  position: fixed;
  width: 90%;
  top: 50%;
  left: 5%;
  transform: translate(0%, -50%);
  height: fit-content;
  max-height: 90vh;
  background-color: var(--color-primary-darken-01);
  border-radius: 5px;
  z-index: 100;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow: auto;
}

@media (min-width: 768px) {
  .Modal {
    width: 40rem;
    left: calc((100% - 40rem) / 2);
  }
  .contrastModal {
    width: 40rem;
    left: calc((100% - 40rem) / 2);
  }
}
