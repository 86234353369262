html {
  font-size: 100%;

  @media (min-width: 1100px) {
    font-size: 112.5%;
  }

  @media (min-width: 1400px) {
    font-size: 125%;
  }
}

body {
  padding: 0;
  margin: 0;
  overflow: overlay;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* ===== Scrollbar CSS ===== */

  .no-scroll {
    overflow-y: hidden !important;
  }

  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #7a747b #ffffff;

  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #7a747b;
    border-radius: 10px;
  }
