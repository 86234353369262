.contlg {
  width: 100%;
  padding-right: 1.875rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto;
}

.br-list .br-item .fa, .br-list .br-item .fab, .br-list .br-item .fad, .br-list .br-item .fal, .br-list .br-item .far, .br-list .br-item .fas, .br-list .br-item .svg-inline--fa {
  text-align: center;
  padding-left: 0% !important;
}



.br-notification .notification-header {
  border-bottom: 1px solid #ccc;
  padding: 20px 10px 20px !important;
}

.br-header .header-actions .dropdown.show .br-notification {
  display: block;
  z-index: 1;
  width: 40vw !important;
  right: 0px !important;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset !important; */
}


.name_logout{
  font-size: 20px;
}

.br-header.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 1.25rem 0 1.025rem
}

.align-items-center {
  align-items: center !important;
  padding-top: 0.7rem;
}

.logo-logo {
  text-decoration: none;
  cursor: pointer;
  margin-left: 4%;
}


@media (max-width: 570px) {
  .contlg {
    padding-right: 0.5rem;
    padding-left: 0.125rem;
  }

  .logo-logo {
    margin-left: 0%;
  }

  .br-header .header-actions .header-functions .br-list, .br-header .header-actions .header-links .br-list {
    background: var(--header-background);
    left: -8em !important;
  }

}

@media (min-width: 700px) and (max-width: 800px) {
  .contlg {
    max-width: 8500px !important;
  }

  .logo-logo {
    margin-left: 4%;
  }


}

@media (min-width: 900px) and (max-width: 1200px) {
  .contlg {
    max-width: 8500px !important;
  }

  .logo-logo {
    margin-left: 3%;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .contlg {
    max-width: 1540px !important;
  }
  .logo-logo {
    margin-left: 2%;
  }
}

@media (min-width: 1600px) {
  .contlg {
    max-width: 8500px !important;
  }

  .logo-logo {
    margin-left: 1.875rem;
  }
}