.br-menu .menu-panel {
    --focus-offset: calc(var(--spacing-scale-half)*-1);
    background: var(--menu-background);
    box-shadow: var(--menu-shadow);
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 0;
    position: relative;
}

.button-menu-logout {
    background: transparent;
    border: none;
    color: #1351B4;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.button-menu-logout {
    background: transparent;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.contrastname{
    color: #ffffff;
    
}

.name{
    color: #1351B4;
}

.menu-folder-2:hover {
    background: #d9e3f3;
    cursor: pointer;
}

.contrastmenu-folder-2:hover  {
    background: #0d367a;
    cursor: pointer;
}

.menu-folder-2 {
    border-bottom: 1px solid #ccc;
}

.br-menu.active .menu-panel {
    position: absolute;
    z-index: calc(var(--menu-zindex) + 1);
}

.menu-close {
    padding-right: 20px;
}


.contrastmenu-folder-2 {
    border-bottom: 1px solid #ccc;
}


.menu-folder {
    border-bottom: 1px solid #ccc;
}

.content_sidebar {
    padding: 10px 15px 10px 40px;
    font-size: 16px;
}

.br-menu .menu-footer .menu-social {
    border-bottom: 1px solid var(--menu-divider);
    padding: 10px 10px !important;
}

.box-instagram-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 5.2px;
    width: 29.5px;
    height: 29px;
    border-radius: 4px;
}


.br-menu .menu-header {
    border-bottom: 1px solid var(--menu-divider);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: var(--spacing-scale-base) var(--spacing-scale-base);
}

.menu-title {
    margin-left: 20px !important;
    padding: 15px 0 15px 0 !important;
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 600;
    font-size: 22px !important;
    line-height: 28px;
}



.br-menu .menu-body {
    overflow-y: auto;
    flex: 1 1;

}

.br-menu .menu-footer .menu-logos {
    border-bottom: 0px solid var(--menu-divider) !important;
}