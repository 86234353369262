.main___container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


.children_class{
    flex: 1;
    min-height: 100%;
    background-color: #f0f0f0f1;
}

.children_contrast{
    background-color: #071D41;

}

.myBtn {
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    outline: none; /* Remove outline */
    cursor: pointer; /* Add a mouse pointer on hover */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
  }