.sized-box__input {
  height: 0.3rem;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.input-contrast input{
  background: #FFFFFF;
  color: #333333;
}

.input-contrast input::placeholder{
  color: #333333;
}

.input-contrast label {
  color: var(--color-secondary-01);
}

.input-contrast p {
  color: var(--color-secondary-01);
}

.input-contrast button {
  color: #333333;
}

/* autocomplete styles */

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}
.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 20rem;
  overflow-y: scroll;
  padding-left: 0;
  padding-right: 0;
  width:100%;
}
.suggestions li {
  padding: 0.5rem;
}
.suggestion-active,
.suggestions li:hover {
  background-color: var(--selected);
  color: white;
  cursor: pointer;
  /* font-weight: 700; */
}
.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}