.br-modal-title {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 16.8px;
    font-size: var(--font-size-scale-up-01);
    font-weight: var(--700);
    margin: 0;
    max-height: 50.162px;
    max-height: var(--font-size-scale-up-07);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100% !important;
}