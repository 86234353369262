.profile-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-secondary-01);

}

.shadow-box {
  background: #ffffff;
  box-shadow: 3px 4px 10px rgba(20, 20, 22, 0.2);
  color: var(--color-primary-darken-02);

}

.shadow-boxcontrast {
  background: #1f3860;
  box-shadow: 3px 4px 10px rgba(20, 20, 22, 0.2);

}

.user-profile {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 5%;
  padding: 1.5% 2% 0% 2%;
  /* Cinza Txt */
  color: #333333;
}

.user-profilecontrast {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 5%;

  padding: 1.5% 2% 0% 2%;
  /* Cinza Txt */
  color: #ffffff;
}

.user-infor {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;

  padding: 0% 2% 1.5% 2%;
  /* Cinza Txt */
  color: #333333;
}

.user-inforcontrast {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;

  padding: 0% 2% 1.5% 2%;
  /* Cinza Txt */
  color: #ffffff;
}

.card-box {
  background: #FFFF;
  box-shadow: 3px 4px 10px rgba(20, 20, 22, 0.2);
  color: var(--color-primary-darken-02);

  /* padding: 0.5% 2%; */
}

.info-card {
  padding: 0.5% 2%;

  font-family: 'Rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  /* Cinza Txt */
  color: #333333;
}

.btn-container {
  flex: 0.25;
}

.save-btn-container {
  display: flex;
  justify-content: space-between;
}


.perfil-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5% 2%;
}

.sized-box {
  height: 1rem;
}

.div-space {
  height: 3rem;
}

.div-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1%;
  margin-left: 0.6%;
  width: 50%;
  color: #333333;
}

.user-cont {
  display: flex;
  flex-direction: column;
}

.title-profile {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 22px;
  margin-bottom: 1%;
  color: #333333;
}

.title-profilecontrast {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 22px;
  margin-bottom: 1%;
  color: #ffffff;
}

.box-row {
  display: flex;
  flex-direction: row;
}

.password-half {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
}

.right-input {
  width: 49%;
}

.left-input {
  width: 49%;
}

.password-input {
  width: 100%;
}

.profilecardcontrast {
  background-color: #071D41;
  margin-bottom: 100px;
}

.profilecard {
  margin-bottom: 100px;
}

.contrastperfil-tile {
  color: var(--color-secondary-01);
}

@media only screen and (max-width: 720px) {

  .perfil-card {
    flex-direction: column;
    justify-items: center;
  }

  .password-half {
    display: block;
  }

  .right-input {
    width: 100%;
  }

  .left-input {
    width: 100%;
  }

  .email-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .div-box {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 15%;
  }

  .title-profile {
    margin-bottom: 5%;
  }
}

@media (min-width: 2500px) {

  .div-box {
    display: flex;
    flex-direction: row;
    width: 50%;
  }


  .perfil-card {
    flex-direction: column;
    justify-items: center;
    width: 50%;
  }

}

@media (max-width: 1400px) and (min-width: 1291px) {

  .div-box {
    display: flex;
    flex-direction: row;
    width: 60%;
  }

}

@media (max-width: 1290px) and (min-width: 1200px) {

  .div-box {
    display: flex;
    flex-direction: row;
    width: 60%;
  }

}

@media (max-width: 1200px) and (min-width: 900px) {

  .perfil-card {
    flex-direction: column;
  }

  .div-box {
    display: flex;
    flex-direction: row;
    width: 80%;
  }
}

@media (max-width: 900px) and (min-width: 730px) {
  .div-box {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .title-profile {
    margin-bottom: 5%;
  }
}



@media only screen and (max-width: 380px) {
  .profile-container {
    flex-direction: column;
    justify-content: center;
  }

  .right-input {
    width: 100%;
  }

  .left-input {
    width: 100%;
  }
}