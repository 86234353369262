.background_ {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.8rem;
  height: auto !important;
  background-color: #f0f0f0f1 !important;
}

.background_contrast {
  background-color: #071D41 !important;
  padding-bottom: 2.8rem;
}

.dashboard_selection_page {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
  width: 55%;
  height: 250% !important;
  margin: 7% auto;
  padding-top: 0.875rem;
  padding-bottom: 4rem;
}

.dashboard {
  cursor: pointer;
  background: #FFFF;
  box-shadow: 3px 4px 10px rgba(20, 20, 22, 0.2);
  color: var(--color-primary-darken-02);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding-top: 35%;
  padding-bottom: 45%;
}

.dashboardcontrast {
  cursor: pointer;
  background: #C5D4EB;;
  color: var(--color-primary-darken-02);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding-top: 35%;
  padding-bottom: 45%;
}


.dashboard:hover {
  background-color: #C5D4EB;
}

.dashboardcontrast:hover {
  background-color:#8C9CB7;
}

.dashboard_box {
  width: 8rem;
  margin-top: 35px;
}

.text {
  display: flex;
  flex-direction: column;
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 2.0625rem;
  text-align: center;
  align-items: center;
  color: var(--color-primary-01);
}

@media only screen and (max-width: 868px) {
  .dashboard_selection_page {
    flex-direction: column;
    width: 85%;
  }

  .dashboard {
    padding-top: 25%;
    padding-bottom: 25%;
  }

}