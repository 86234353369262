.background_ {
  background-color: var(--color-secondary-01);
}

.card_waiting{
  background-color: blueviolet;
}

.background_contrast {
  background-color: var(--color-primary-darken-01);
}

.main__page {
  width: 85%;
  margin: 0 auto;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.dashboard-personalization-button{
  display: flex;
  justify-content: center;
  align-items: center;
}

.row__inner {
  flex: 1;
  margin: 0.5rem;
}

.row__invisible__div {
  flex: 1;
  height: 15rem;
}

.row{
  flex-wrap: nowrap;
  flex-direction: row;
}

@media only screen and (max-width: 788px) {
  .row__invisible__div {
    display: none;
  }
  .row{
    flex-direction: column;
  }
}
