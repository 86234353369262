.contrast {
  background-color: var(--color-primary-darken-02);
  color: var(--color-secondary-01) !important;
}

.br-list .contrast {
  background-color: var(--color-primary-darken-02);
  color: var(--color-secondary-01);
  
}

.br-list .header {
  align-items: var(--spacing-vertical-center);
  display: flex;
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-bold);
  justify-content: space-between;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x);
}

.br-list .br-item {
  text-align: var(--spacing-horizontal-left);
  width: 100%;
}

.br-list .br-item .svg-inline--fa,
.br-list .br-item .fa,
.br-list .br-item .fab,
.br-list .br-item .fad,
.br-list .br-item .fal,
.br-list .br-item .far,
.br-list .br-item .fas {
  text-align: center;
}

.br-list .br-item.open+.br-list,
.br-list .br-item.open .br-list {
  max-height: 200em;
}

.br-list .br-item>.content {
  align-items: var(--spacing-vertical-top);
  display: flex;
}

.br-list .br-item>.content>.content {
  flex: 1;
}

.br-list .br-item .content:hover button.br-button {
  background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)));
}

.br-list .br-item .br-checkbox,
.br-list .br-item .br-radio {
  --interactive-rgb: var(--rgb-primary-default);
  --item-padding-x: 0;
  --item-padding-y: 0;
}

.br-list .br-item .br-checkbox input:hover:not(:disabled)+label::before,
.br-list .br-item .br-radio input:hover:not(:disabled)+label::before {
  background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)));
}

.br-list .br-list {
  max-height: 0;
  overflow: hidden;
  transition: all 400ms ease-in-out;
}

.br-list.horizontal {
  display: flex;
  flex-wrap: wrap;
}

.br-list.horizontal>.header {
  flex-grow: 1;
  width: 100%;
}

.br-list.horizontal>.header+.br-divider {
  border-top: 1px solid var(--color-secondary-04);
  width: 100%;
}

.br-list.horizontal>.br-item {
  flex: 1;
  height: auto;
  min-height: 56px;
  width: auto;
}

.br-list.horizontal>.br-item.one-line>.content {
  height: 56px;
}

.br-list.horizontal>.br-item.two-lines>.content {
  height: 72px;
}

.br-list.horizontal>.br-item.three-lines>.content {
  height: 6em;
}

.br-list.horizontal>.br-item+.br-divider {
  border-right: 1px solid var(--color-secondary-04);
  border-top: 0;
  width: 1px;
}

.br-list.horizontal[data-toggle]>.br-item,
.br-list.horizontal.toggle>.br-item {
  display: block;
}

.br-list[data-one-line] .br-item,
.br-list[data-one-line] .br-list .br-item {
  height: 2em;
}

.br-list[data-one-line] .br-item .content,
.br-list[data-one-line] .br-list .br-item .content {
  height: auto;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  text-overflow: unset;
  white-space: initial;
}

.br-list[data-two-lines] .br-item,
.br-list[data-two-lines] .br-list .br-item {
  height: 4em;
}

.br-list[data-two-lines] .br-item .content,
.br-list[data-two-lines] .br-list .br-item .content {
  height: auto;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  text-overflow: unset;
  white-space: initial;
}

.br-list[data-three-lines] .br-item,
.br-list[data-three-lines] .br-list .br-item {
  height: 6em;
}

.br-list[data-three-lines] .br-item .content,
.br-list[data-three-lines] .br-list .br-item .content {
  height: auto;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  text-overflow: unset;
  white-space: initial;
}

/*# sourceMappingURL=list.css.map*/