.sized-box__form {
  height: 1rem;
}

.Title {
  font-size: 1.125rem;
  font-weight: bold;
}

.Description {
  font-weight: bold;
}

.contrastTitle {
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--color-secondary-01);
}

.contrastDescription {
  font-weight: bold;
  color: var(--color-secondary-01);
}

input[type="checkbox"] {
  appearance: checkbox;
}

.contrastCheckbox{
  color: var(--color-secondary-01);
}