#seta_bc{
  margin-right: 9px;
  margin-left: -10px;
}

.br-breadcrumb {
  display: inline-grid;
  font-size: var(--font-size-scale-down-01);
  font-weight: var(--font-weight-medium);
  position: relative;
}

.br-breadcrumb .crumb-list {
  align-items: center;
  border: 0;
  display: flex;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
}

.br-breadcrumb .crumb {
  align-items: center;
  display: flex;
  height: var(--spacing-scale-5x);
  margin: auto 0 auto -5px;
}

.br-breadcrumb .crumb .icon {
  color: var(--border-color);
  font-size: var(--icon-size-sm);
  margin-right: -6px;
}

.br-breadcrumb .crumb {
  overflow: hidden;
  font-family: 'Rawline' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.1875rem;
  padding: 0 6px;
}

.Text {
  color: #1351B4;
  overflow: hidden;
  font-family: 'Rawline' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.1875rem;
  padding: 0 6px;
  align-items: center;
  border: 0;
  display: flex;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
}

.contrastText {
  color: aliceblue !important;
  font-family: 'Rawline' !important;
  font-size: 0.875rem !important;
  align-items: center;
  border: 0;
  display: flex;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
}

.Text_secondary {
  
  overflow: hidden;
  font-family: 'Rawline' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.1875rem;
  padding: 0 6px;
  align-items: center;
  border: 0;
  display: flex;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
}

.contrastText_secondary {
  color: aliceblue !important;
  font-family: 'Rawline' !important;
  font-size: 0.875rem !important;
  align-items: center;
  border: 0;
  display: flex;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
}

.Icon{
  color: #1351B4;
}
.contrastIcon{
  color: aliceblue;
}
.br-breadcrumb .crumb:last-child {
  overflow: hidden;
  font-family: 'Rawline' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.1875rem;
  padding: 0 6px;

}

.br-breadcrumb .home,
.br-breadcrumb .menu-mobil {
  --focus-offset: calc(var(--spacing-scale-half) * -1);
  margin-left: 0;
  margin-right: -3px;
}

@media (max-width: 991px) {
  .br-breadcrumb .crumb :not(.br-button) {
    display: block;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .br-breadcrumb .menu-mobil,
  .br-breadcrumb .menu-mobil+.crumb,
  .br-breadcrumb .home+.crumb {
    display: flex;
  }
}

@media (max-width: 575px) {
  .br-breadcrumb .menu-mobil>.icon {
    display: none;
  }

  .br-breadcrumb .br-card {
    left: var(--spacing-scale-base);
    width: 250px;
  }
}

.br-breadcrumb .br-item {
  --interactive: var(--color);
  --interactive-rgb: var(--color-rgb);
  display: block;
  padding: var(--spacing-scale-2x);
}