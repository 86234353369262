.tooltip_container_ {
  display: flex;
  flex-direction: column;
  background-color: var(--color-secondary-01);
  color: var(--text-color);
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.text_tooltip{
  column-count: 2;
  column-gap: 10px; 
  max-width: 400px;
}

.tooltip_container_contrast {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary-darken-02);
  color: var(--color-secondary-01);
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
}