p {
  margin: 0;
}

.MainPage {
  display: flex;
  flex: 1;
}

.contrastMainPage {
  display: flex;
  flex: 1;
  background-color: #071D41;
}

.Container {
  width: 97%;
  margin: 3.5rem auto;
}

.contrastContainer {
  width: 97%;
  margin: 2.5rem auto;
}

.section {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  margin: 1rem;
  margin-top: 4%;
  margin-bottom: 4%;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.titleHome {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 1.7rem;
  text-align: center !important;
  color: #333333;

}

.contrasttitleHome {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 26px !important;
  line-height: 1.7rem;
  text-align: center;
  color: var(--color-secondary-01);
}

.Subtitle {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4rem;
  text-align: center;
  color: #333333;

}

.contrastSubtitle {
  font-size: 16px;
  text-align: center;
  color: var(--color-secondary-01);
}

.Question {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2rem;
  text-align: center;
  color: #333333;
}

.contrastQuestion {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  color: var(--color-secondary-01);
}

.Content {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #333333;
  margin: auto;
  width: 40%;
}

.contrastContent {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  margin: auto;
  width: 40%;
  color: var(--color-secondary-01);
}

.section_button {
  display: flex;
  flex: 1;
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0.2rem;
  justify-content: center;
  align-items: center;
}

.sized_box {
  display: flex;
  flex: 1;
  height: 1rem;
}


@media (max-width: 600px) {
  .Content {
    width: 90%;
  }

  .contrastContent {
    width: 90%;
    color: var(--color-secondary-01);
  }
}

@media (max-width: 900px) and (min-width: 600px) {
  .Content {
    width: 80%;
  }

  .contrastContent {
    width: 90%;
    color: var(--color-secondary-01);
  }
}

@media (min-width: 3000px) {
  .section {
    display: flex;
    flex: 1;
    padding: 1rem;
    margin: 1rem;
    margin-top: 8%;
    justify-content: center;
    text-align: center;
    align-items: center;

  }

}