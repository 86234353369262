

.buttonSign-in{
    background-color: #1351B4;
    --button-radius: 100em;
    --button-xsmall: 24px;
    --button-small: 32px;
    --button-medium: 40px;
    --button-large: 48px;
    --button-size: var(--button-medium);
    align-items: center;
    border: 0;
    border-radius: var(--button-radius);
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: var(--font-size-scale-up-01);
    font-weight: var(--font-weight-semi-bold);
    height: var(--button-size);
    justify-content: center;
    overflow: hidden;
    padding: 0 var(--spacing-scale-3x);
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    --background: var(--gray-2);
    --sign-in-img: 20px;
    padding: 0 30px ;
}

.buttonSign-incontrast{
    background-color: white;
    --button-radius: 100em;
    --button-xsmall: 24px;
    --button-small: 32px;
    --button-medium: 40px;
    --button-large: 48px;
    --button-size: var(--button-medium);
    align-items: center;
    border: 0;
    border-radius: var(--button-radius);
    color: #0a2552;
    cursor: pointer;
    display: inline-flex;
    font-size: var(--font-size-scale-up-01);
    font-weight: var(--font-weight-semi-bold);
    height: var(--button-size);
    justify-content: center;
    overflow: hidden;
    padding: 0 var(--spacing-scale-3x);
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    --background: var(--gray-2);
    --sign-in-img: 20px;
    padding: 0 30px ;

}

@media (min-width: 576px) {
  .br-sign-in.block-sm,
  .br-button.sign-in.block-sm {
    width: 100%;
  }
  .br-sign-in.auto-sm,
  .br-button.sign-in.auto-sm {
    width: auto;
  }
}
@media (min-width: 992px) {
  .br-sign-in.block-md,
  .br-button.sign-in.block-md {
    width: 100%;
  }
  .br-sign-in.auto-md,
  .br-button.sign-in.auto-md {
    width: auto;
  }
}
@media (min-width: 1280px) {
  .br-sign-in.block-lg,
  .br-button.sign-in.block-lg {
    width: 100%;
  }
  .br-sign-in.auto-lg,
  .br-button.sign-in.auto-lg {
    width: auto;
  }
}
@media (min-width: 1600px) {
  .br-sign-in.block-xl,
  .br-button.sign-in.block-xl {
    width: 100%;
  }
  .br-sign-in.auto-xl,
  .br-button.sign-in.auto-xl {
    width: auto;
  }
}
