.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.error-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 5rem 10rem 5rem;
    padding: 10rem;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.error-container h1 {
    font-size: 140px;
    font-weight: 700;
    color: #071d41;
}

.error-container h5 {
    font-size: 30px;
    color: #071d41;
}

.error-container h6{
    font-size: 20px;
    font-weight: 500;
    color: #5f6368;
}

@media (max-width: 828px) {
    .error-text{
        margin: 5rem 1rem 10rem 1rem;
        padding: 4rem;
    }

    .error-container h1 {
        font-size: 5rem;
        font-weight: 700;
    }

    .error-container h5 {
        font-size: 1.2rem;
    }
}