.perfil-container {
  display: flex;
  justify-content: center;
}

.div-container-recover{
  display: flex;
  justify-content: center;
}

.password-half {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-right: 80px;
}

.password-input {
  width: 100%;
}

.right-input {
  width: 49%;
}

.left-input {
  width: 49%;
}

.outer-container-recover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 60%;
  margin: 10%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.contrastouter-container-recover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1f3860;
  width: 60%;
  margin: 10%;
  box-shadow: rgba(0, 0, 0, 0) 0px 2px 8px 0px;
}

@media only screen and (max-width: 760px) {
  .outer-container-recover {
    width: 80%;
  }

  .contrastouter-container-recover {
    width: 80%;
  }

  .perfil-container h2 {
    font-size: 22px;
  }

  .perfil-card {
    flex-direction: column;
  }

  .password-half {
    display: block;
  }

  .right-input {
    width: 100%;
  }

  .left-input {
    width: 100%;
  }

}