.br-fter {
    --text-color: var(--color-secondary-01);
    --interactive: var(--color-primary-pastel-01);
    --interactive-rgb: var(--rgb-primary-pastel-01);
    --visited: var(--color-secondary-04);
    --hover: var(--surface-opacity-sm, 0.3);
    --pressed: var(--surface-opacity-lg, 0.65);
    --focus-color: var(--color-support-10);
    --active: var(--color-secondary-01);
    background: #071D41;
    color: var(--color-secondary-01);
    display: block;
}

.contrastbr-fter {
    --text-color: var(--color-secondary-01);
    --interactive: var(--color-primary-pastel-01);
    --interactive-rgb: var(--rgb-primary-pastel-01);
    --visited: var(--color-secondary-04);
    --hover: var(--surface-opacity-sm, 0.3);
    --pressed: var(--surface-opacity-lg, 0.65);
    --focus-color: var(--color-support-10);
    --active: var(--color-secondary-01);
    background: #FFFFFF;
    color: var(--color-secondary-01);
    display: block;
}

.group {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.group-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 5% 0 3%;
}

.element {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 4% 0 0;
}

.subject_a {
    padding-left: 12%;
}

.subject_b {
    padding-left: 4%;
    width: 159px;
}

.subject {
    color: #ffffff;
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 0 5%;
}

.contrastsubject {
    color: #333333;
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 0 5%;
}

.topic {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

}


.contrasttopic {
    color: #333333;
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.footer_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 5% 2% 2% 2%;
}

.logo {
    margin: 0 0 5% 2%;
    padding-top: 4%;
}

.img_gov {
    filter: brightness(0.23);
    max-height: 60px;
    max-width: 200px;
}

.contrastimg_gov {
    max-height: 60px;
    max-width: 200px;
}


.img_acesso {
    filter: brightness(0.23);
    max-height: 60px;
    max-width: 200px;
}

.contrastimg_acesso {
    max-height: 60px;
    max-width: 200px;
}

.img_social {
    max-height: 70px;
    max-width: 210px;
}

.contrastimg_social {
    max-height: 70px;
    max-width: 210px;
}

.social-network {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.contrastmargin-3 {
    margin-right: 6px;
    filter: contrast(200%);
}

.margin-3 {
    margin-right: 6px;
    filter: brightness(0.23);
}

.gov {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.box-instagram {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2d3136;
    cursor: pointer;
    margin-left: 2%;
    width: 48px;
    height: 40px;
    border-radius: 5px;
}

.contrastbox-instagram {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    cursor: pointer;
    margin-left: 2%;
    width: 48px;
    height: 42px;
    border-radius: 5px;
}

.info {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #333333;
}

.contrastinfo {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

@media (max-width: 962px) {
    .footer_block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20% 3.5% 10% 0;
    }

    .img_social {
        max-height: 40px;
        max-width: 110px;
        margin-top: 20%;
        margin-left: 12%;
    }

    .contrastimg_social {
        max-height: 40px;
        max-width: 110px;
        margin-top: 50%;
        margin-left: 12%;
    }

    .img_acesso {
        max-height: 40px;
        max-width: 110px;
    }
    
    .contrastimg_acesso {
        max-height: 40px;
        max-width: 110px;
    }
    
    .gov {
        margin-left: 4%;
        align-items: flex-end;
    }

    .img_gov {
        margin: 50px 0 30px 2%;
    }

    .contrastimg_gov {
        margin: 50px 0 30px 2%;
    }

    .group {
        margin: 0 6% 15% 6%;
    }
}

@media only screen and (max-width: 1000px) {
    .group {
        flex-direction: column;
    }
}